'use client';

import ReUsableSectionHeading from '@/components/ReUsables/ReUsableSectionHeading';
import ReUsableSlider from '@/components/ReUsables/ReUsableSlider';
import { EventGalleryBreakPoint } from '@/constants/home';
import { Paper } from '@mui/material';
import Image from 'next/image';

export default function EventGalleryPage({ galleryData }: any) {
    const photos = [];
    if (galleryData) {
        for (let photo of galleryData) {
            for (let file of photo.files) {
                if (file?.fileLocation?.includes('https')) {
                    photos.push(file.fileLocation);
                }
            }
        }
    }

    return (
        <div className="py-2 mt-2">
            <ReUsableSectionHeading name="Event Gallery" href="#" />
            <ReUsableSlider slideNumber={1} gap={20} breakpoints={EventGalleryBreakPoint} loading>
                {Array.isArray(photos) &&
                    photos.slice(0, 6).map((item, idx) => {
                        return (
                            <Paper key={idx}>
                                <Image width={300} height={200} alt="" src={item} className="w-full" />
                            </Paper>
                        );
                    })}
            </ReUsableSlider>
        </div>
    );
}
