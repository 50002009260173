'use client';
import Image from 'next/image';
import DOMPurify from 'isomorphic-dompurify';
import { enCodeLink, formatDate } from '@/utils';
import {  Paper } from '@mui/material';
// import { Button } from '@mui/material';
import Link from 'next/link';
// import { convert } from 'html-to-text';

export default function ReUsableUpdateCard({ data }: any) {
    if (!data) return <h1>Something went wrong...</h1>;
    const { para = '', date = '', headLine = '', mediaLink = '', editorialId: id } = data || {};
    // const text = convert(decodeURI(headLine), {});
    
    return (
        <Paper className="h-full">
            <div className="flex flex-col p-2 h-full">
                <div className="w-full overflow-hidden rounded-sm">
                 <Link href={`${enCodeLink(headLine, id)}`}>
                 <Image
                        src={mediaLink}
                        width={400}
                        height={250}
                        alt="Update Image"
                        className="skeleton max-h-[300px] hover-image hover:scale-105 transition-all"
                    />
                 </Link>
                </div>
                <div className="flex-grow">
                  <Link href={`${enCodeLink(headLine, id)}`}>
                  <div
                        className="font-bold text-2xl line-clamp-3 mt-2 editor  justify-normal hover:text-[#05B4A2] transition-all"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(headLine),
                        }}
                    /></Link>
                    <div
                        className="line-clamp-2 text-[#2C2A2A] editor text-hover hidden"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(para) }}
                    />
                </div>
                <div className="flex justify-between items-center pt-2">
                    <p className="text-[#606060] font-bold">{formatDate(date)}</p>
                    {/* <Link href={`${text.replaceAll(" ","-")}${id}`}> */}
                    <Link href={`${enCodeLink(headLine, id)}`}>
                        <button className=" hover:bg-[#05B4A2] hover:text-white  border-[#05B4A2] border-[1px] py-1 px-3 rounded text-[#05B4A2] transition-all duration-300" >
                            Read more
                        </button>
                    </Link>
                </div>
            </div>
        </Paper>
    );
}
